<template>
  <v-row no-gutters>
    <v-col cols="12" class="d-flex mb-2">
      <v-spacer />
      <v-btn small elevation="" @click="refresh" outlined color="primary">Refresh</v-btn>
    </v-col>

    <v-col cols="12">
      <v-stepper v-model="e1">
        <v-stepper-header class="" style="">
          <v-stepper-step :complete="e1 > 1" step="1"> Production file settings </v-stepper-step>

          <v-divider></v-divider>

          <v-stepper-step :complete="e1 > 2" step="2"> Preview file settings </v-stepper-step>
          <v-divider></v-divider>
          <v-stepper-step step="3"> Preview </v-stepper-step>
        </v-stepper-header>

        <v-stepper-items id="content-canvas">
          <v-stepper-content step="1">
            <label title="Add a background" class="myFile2 mb-3 mb-3 d-inline-block"
              ><span class="pointer btn-upload"> Add image production setting </span>&nbsp;
              <input ref="fileUploader" @change="uploadImageProduct" type="file" id="file2" />
            </label>
            <v-row no-gutters>
              <v-col cols="3" class="ml-2"> </v-col>
              <SelectionStyle
                :isChangeOption="isChangeOption"
                v-if="!overlay && !isLoadingStyle"
                :fonts="fonts"
                @addText="addText('product')"
                @deleteSelection="deleteSelection('product')"
                @updateData="getDataStyle"
                @loadFont="getFontPersonalize"
                :data="optionsProductSetting"
                :type="'product'"
              />
            </v-row>
            <v-col cols="12" class="product-canvas d-flex justify-center align-center">
              <canvas id="cp" width="0px" height="0px"></canvas>
            </v-col>
            <v-btn color="primary" @click="stepSetupProduct"> Continue </v-btn>

            <!-- <v-btn text>
              Cancel
            </v-btn> -->
          </v-stepper-content>

          <v-stepper-content step="2">
            <label title="Add a background" class="myFile2 mb-3 mb-3 d-inline-block"
              ><span class="pointer btn-upload"> Add image preview setting </span>&nbsp;
              <input ref="fileUploader" @change="uploadImagePreview" type="file" id="file2" />
            </label>
            <v-row no-gutters>
              <v-col cols="3" class="ml-2"> </v-col>
              <SelectionStyle
                :isChangeOption="isChangeOption"
                v-show="!overlay && !isLoadingStyle"
                @addText="addText('preview')"
                @deleteSelection="deleteSelection('preview')"
                @updateData="getDataStyle"
                :data="optionsPreviewSetting"
                @loadFont="getFontPersonalize"
                :fonts="fonts"
                :type="'preview'"
              />
            </v-row>
            <v-col cols="12" class="preview-canvas d-flex justify-center align-center">
              <canvas id="cpr"></canvas>
            </v-col>

            <v-btn color="primary" @click="stepSetupPreview"> Done </v-btn>

            <v-btn text @click="e1 = 1"> Back </v-btn>
          </v-stepper-content>
          <v-stepper-content step="3">
            <v-row no-gutters>
              <h4>Image production setting</h4>
              <v-col cols="12" class="product-canvas d-flex justify-center align-center">
                <div class="personalize-production"></div>
              </v-col>
              <v-col cols="12" class="my-3">
                <v-divider class=""></v-divider>
              </v-col>
              <h4 class="mb-2">Image preview setting</h4>
              <v-col cols="12" class="preview-canvas d-flex justify-center align-center">
                <div class="personalize-preview"></div>
              </v-col>
            </v-row>
            <v-row no-gutters class="my-3">
              <v-btn :loading="isLoading" small @click="submit" elevation="" color="primary"> Save </v-btn>
              <v-btn text @click="e1 = 2"> Back </v-btn>
            </v-row>
          </v-stepper-content>
        </v-stepper-items>
      </v-stepper>
    </v-col>
    <v-overlay :value="overlay">
      <div class="fz-18">
        Upload image ....
        <v-progress-circular :size="30" color="primary" indeterminate></v-progress-circular>
      </div>
    </v-overlay>
    <canvas id="c1" width="1" height="1"></canvas>
  </v-row>
</template>

<script>
import { fabric } from 'fabric';
import $ from 'jquery';
import imageHelpers from '@/helpers/image';
import SelectionStyle from './components/SelectionStyle.vue';
import { log, parse } from 'handlebars';
import { personalizesApi } from '@/apis/personalizes';
import { uploadService } from '@/apis/upload.s';
import axios from 'axios';
import logic from './logic-personal';
// var FontFaceObserver = require('fontfaceobserver');

export default {
  components: { SelectionStyle },
  mixins: [logic],
  data() {
    let defaultSetting = {
      align: 'center',
      bold: false,
      fontColor: '#000000',
      fontSize: '25px',
      italic: false,
      underline: false,
      fontFamily: 'Arial',
      effect: 'STRAIGHT',
      maxLength: 1,
      text: 'A',
      caps: false,
    };
    return {
      isChangeOption: false,
      isAdd: true,
      fonts: [],
      e1: 1,
      imageHelpers,
      canvas: {
        product: null,
        preview: null,
      },
      optionsProductSetting: { ...defaultSetting },
      optionsPreviewSetting: { ...defaultSetting },
      defaultSetting,
      overlay: false,
      formData: {
        previewSvg: null,
        productionSvg: null,
        previewJson: null,
        productionJson: null,
      },
      imageUrl: {
        product: null,
        preview: null,
      },

      image: {
        src: null,
        size: {
          width: 0,
          height: 0,
        },
      },

      isLoading: false,
      isLoadingStyle: false,
      maxWidth: 0,
      listFontSize: [],
      previewSetting: {
        product: null,
        preview: null,
      },
      loadFCV: null,
    };
  },
  async mounted() {
    this.maxWidth = $('#content-canvas').width() - 30;
    this.canvas.product = new fabric.Canvas('cp');
    this.canvas.preview = new fabric.Canvas('cpr');
    this.loadFCV = new fabric.Canvas('c1');
    await this.getFontPersonalize();
    this.previewSetting.product = new fabric.Canvas('cpp');
    this.previewSetting.preview = new fabric.Canvas('cprp');
    let vm = this;
    //fire('mouse:dblclick'
    this.canvas.product.on('mouse:down', function(opt) {
      let target = opt.target;
      if (opt.target) {
        console.log('mouse', target);

        let t1 = opt.target._objects[1];
        vm.optionsProductSetting.text = t1.text;
        vm.optionsProductSetting.align = t1.textAlign;
        vm.optionsProductSetting.fontFamily = t1.fontFamily;
        vm.optionsProductSetting.bold = t1.fontWeight && t1.fontWeight === 'bold' ? true : false;
        vm.optionsProductSetting.fontStyle = t1.fontStyle && t1.fontStyle === 'italic' ? true : false;
        vm.optionsProductSetting.fontColor = t1.fill;
        vm.optionsProductSetting.effect = t1.effect;
        vm.optionsProductSetting.underline = t1.underline;
        vm.optionsProductSetting.fontSize = `${t1.fontSize}px`;
        vm.optionsProductSetting.caps = t1.styles === 'uppercase' ? true : false;

        // t1.width = t1.width * opt.target.scaleX;
        let sX = target.scaleX;
        let sY = target.scaleY;
        target.width *= sX;
        target.height *= sY;
        target.scaleX = 1;
        target.scaleY = 1;
        opt.target._objects[1].width = target.width;
        opt.target._objects[0].width = target.width;

        vm.$forceUpdate();
      }
    });
    this.canvas.product.on('object:scaling', function(opt) {
      var target = opt.target;
      if (!target || target.type !== 'group') {
        return;
      }
      var sX = target.scaleX;
      var sY = target.scaleY;
      target.width *= sX;
      target.height *= sY;
      target.scaleX = 1;
      target.scaleY = 1;
      opt.target._objects[1].width = target.width;
      opt.target._objects[0].width = target.width;
    });

    this.canvas.preview.on('mouse:down', function(opt) {
      try {
        let target = opt.target;
        if (opt.target) {
          console.log(opt.target);
          let t1 = opt.target._objects[1];
          vm.optionsPreviewSetting.text = t1.text;
          vm.optionsPreviewSetting.align = t1.textAlign;
          vm.optionsPreviewSetting.fontFamily = t1.fontFamily;
          vm.optionsPreviewSetting.bold = t1.fontWeight && t1.fontWeight === 'bold' ? true : false;
          vm.optionsPreviewSetting.fontStyle = t1.fontStyle && t1.fontStyle === 'italic' ? true : false;
          vm.optionsPreviewSetting.fontColor = t1.fill;
          vm.optionsPreviewSetting.effect = t1.effect;
          vm.optionsPreviewSetting.underline = t1.underline;
          vm.optionsPreviewSetting.fontSize = `${t1.fontSize}px`;
          vm.optionsPreviewSetting.caps = t1.styles === 'uppercase' ? true : false;
          let sX = target.scaleX;
          let sY = target.scaleY;
          target.width *= sX;
          target.height *= sY;
          target.scaleX = 1;
          target.scaleY = 1;
          opt.target._objects[1].width = target.width;
          opt.target._objects[0].width = target.width;
          vm.$forceUpdate();
        }
      } catch (error) {
        console.log(error, 'error');
      }
    });
    this.canvas.preview.on('object:scaling', function(opt) {
      let target = opt.target;
      if (!target || target.type !== 'group') {
        return;
      }
      let sX = target.scaleX;
      let sY = target.scaleY;
      target.width *= sX;
      target.height *= sY;
      target.scaleX = 1;
      target.scaleY = 1;
      opt.target._objects[1].width = target.width;
      opt.target._objects[0].width = target.width;
    });
  },
  methods: {
    async submit() {
      try {
        this.isLoading = true;
        let res = await personalizesApi.create({
          productId: this.$route.params.id,
          previewSvg: this.removeStringXML(this.formData.previewSvg),
          productionSvg: this.removeStringXML(this.formData.productionSvg),
          createdById: this.$store.state.auth.user.id,
          fonts: this.checkFonts(),
          //one 1 group [text,rect]
          productionJson: JSON.stringify(this.formData.productionJson),
          previewJson: JSON.stringify(this.formData.previewJson),
          countTexts: this.formData.previewJson.objects.length,
          productAttributes: this.formData.productionJson.objects,
          previewAttributes: this.formData.previewJson.objects,
        });
        this.$router.push({ name: 'website.product-personalized' });
      } catch (error) {
        console.log(error, 'error');
      }
      this.isLoading = false;
    },
  },
};
</script>

<style lang="scss">
.myFile {
  position: relative;
  overflow: hidden;
  float: left;
  clear: left;
}

.myFile input[type='file'] {
  display: block;
  position: absolute;
  top: 0;
  right: 0;
  opacity: 0;
  font-size: 30px;
  filter: alpha(opacity=0);
}

.myFile2 input[type='file'] {
  display: block;
  position: absolute;
  top: 0;
  right: 0;
  opacity: 0;
  font-size: 30px;
  filter: alpha(opacity=0);
}
.myFile2 {
  .btn-upload {
    background: #1f739e;
    font-weight: bold;
    color: white;
    padding: 8px 8px;
    display: flex;
    align-items: center;
    border-radius: 5px;
  }
}
</style>
